const validateName = (name) => {
  return /^[A-Za-zÀ-ÖØ-öø-ÿ\s.'-]+\s[A-Za-zÀ-ÖØ-öø-ÿ\s.'-]+$/.test(name);
};

const validateEmail = (email) => {
  return /^.+@.+\..+$/.test(email);
};

const validatePhone = (phone) => {
  return /^\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}$/.test(phone);
};

const validateHeaderMessage = (message) => {
  return /^.{10,}$/.test(message);
};

/**
 * This function takes the input of the contact form and ensures that it is
 * valid. It checks each input separately, and also ensures that the header and
 * message are not copies of each other.
 *
 * @param {string} name The name to be validated.
 * @param {string} email The email to be validated.
 * @param {string} phone The phone number to be validated.
 * @param {string} header The header to be validated.
 * @param {string} message The message to be validated.
 * @returns An array of strings representing all problems with the input.
 */
const validate = (name, email, phone, header, message) => {
  const validation = {
    name: validateName(name),
    email: validateEmail(email),
    phone: validatePhone(phone),
    header: validateHeaderMessage(header),
    message: validateHeaderMessage(message),
    headerMessageMatch: header !== message
  };
  const problems = Object.keys(validation).filter((key) => {
    return validation[key] === false;
  });
  return problems;
};

module.exports = validate;
module.exports = {
  validateName,
  validateEmail,
  validatePhone,
  validateHeaderMessage
};
