// Stuff from react libraries.
import { createContext, useContext, useEffect, useState } from 'react';
// CSS
import '../../css/contactpage.css';

import {
  validateName,
  validateEmail,
  validatePhone,
  validateHeaderMessage
} from '../../functions/validators';
import { requestSendContactFormEmail } from '../../functions/requests';

/** The ContactContext keeps track of the state of the message. */
const ContactContext = createContext();

const ContactPage = () => {
  const [contactState, setContactState] = useState({
    name: '',
    email: '',
    phone: '',
    header: '',
    message: '',
    send: false,
    feedback: ''
  });

  const initialContactContext = { contactState, setContactState };

  return (
    <div className='ContactPage'>
      <ContactContext.Provider value={initialContactContext}>
        <h1>Let's keep in touch!</h1>
        <ContactForm />
      </ContactContext.Provider>
    </div>
  );
};

const ContactForm = () => {
  const { contactState, setContactState } = useContext(ContactContext);
  console.log(contactState);

  useEffect(() => {
    const sendButton = document.querySelector('#sendbutton');
    if (
      contactState.name &&
      contactState.email &&
      contactState.phone &&
      contactState.header &&
      contactState.message
    ) {
      sendButton.disabled = false;
    } else {
      sendButton.disabled = true;
    }
  }, [
    contactState.name,
    contactState.email,
    contactState.phone,
    contactState.header,
    contactState.message
  ]);

  useEffect(() => {
    (async () => {
      if (contactState.send === true) {
        const response = await requestSendContactFormEmail(
          contactState.name,
          contactState.email,
          contactState.phone,
          contactState.header,
          contactState.message
        );
        if (response.messageSent) {
          if (response.confirmationSent) {
            setContactState({
              ...contactState,
              feedback: `Email sent successfully. A confirmation email was sent to
            ${contactState.email}. Please expect a response within 2 business
            days.`
            });
          } else {
            setContactState({
              ...contactState,
              feedback: `Email sent successfully. There was a problem delivering
            a confirmation email to ${contactState.email}. If this email is
            correct, you can expect a response within 2 business days.`
            });
          }
        } else {
          setContactState({
            ...contactState,
            feedback: `Message could not be sent! Reason: ${response.message}`
          });
        }
      }
    })();
  }, [contactState.send]);

  return (
    <div className='ContactForm box'>
      <CFGroup label='First and last name' id='name' validator={validateName} />
      <CFGroup label='Email address' id='email' validator={validateEmail} />
      <CFGroup label='Phone number' id='phone' validator={validatePhone} />
      <CFGroup
        label='Subject'
        id='header'
        rows={2}
        validator={validateHeaderMessage}
      />
      <CFGroup
        label='Message'
        id='message'
        rows={10}
        validator={validateHeaderMessage}
      />
      <SendButton />
      <span>{contactState.feedback}</span>
    </div>
  );
};

const CFGroup = ({ label, id, rows = 1, validator }) => {
  const { contactState, setContactState } = useContext(ContactContext);

  const validateField = () => {
    /*
      This is the onblur function for the inputs.
      If the input is valid, it will add it to the ContactContext.
      If not, it will highlight the box and clear the value.
    */
    const element = document.querySelector(`#${id}`);
    const input = element.value.trim();
    if (validator(input)) {
      // Input is valid.
      element.style.borderLeft = '2px solid var(--spearmint)';
      element.style.borderTop = element.style.borderLeft;
      element.style.borderRight = '2px solid var(--charcoal)';
      element.style.borderBottom = element.style.borderRight;
      setContactState({
        ...contactState,
        [id]: input
      });
    } else {
      // Mark it so the user knows their input is invalid.
      element.style.border = '2px solid var(--light-rust)';
      setContactState({
        ...contactState,
        [id]: ''
      });
    }
  };

  return (
    <div className='CFGroup'>
      <label htmlFor={id}>{label}</label>
      {rows === 1 ? (
        <input type='text' id={id} onBlur={validateField} />
      ) : (
        <textarea id={id} rows={rows} onBlur={validateField}></textarea>
      )}
    </div>
  );
};

const SendButton = () => {
  const { contactState, setContactState } = useContext(ContactContext);

  const initiateSend = () => {
    setContactState({
      ...contactState,
      send: true
    });
  };

  return (
    <button id='sendbutton' onClick={initiateSend}>
      Send message
    </button>
  );
};

export default ContactPage;
