// Stuff from react libraries
import { createContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Components
import Header from './header';
import HomePage from './pages/home';
import ResumePage from './pages/resume';
import ProjectsPage from './pages/projects';
import ContactPage from './pages/contact';
import GoodBadChad from './pages/projects/goodbadchad';
// CSS
import '../css/general.css';
import '../css/text.css';

/**
 * The LanguageContext - shared by all components within the App - determines
 * the language that any and all text should be presented in. It is able to be
 * toggled with the language switch in the header. Its initial state is
 * based on the default language of the browser used to view the application.
 * It can be one of two values - 'en' for English and 'es' for Spanish.
 *
 * NOTE: Any other languages must be achieved via the translation technology in
 * the browser. The English and Spanish translations will have been programmed
 * in by me.
 */
const LanguageContext = createContext();

/**
 * The App is a container for all other components.
 *
 * It is the provider for the LanguageContext, which is used by all
 * child components.
 *
 * It contains the header, main, and footer. It uses BrowserRouter to
 * determine which content should be shown in the main section.
 *
 * @returns The App component.
 */
const App = () => {
  const browserLanguage = navigator.language.slice(0, 2);

  const [languageState, setLanguageState] = useState('en');

  const initialLanguageContext = { languageState, setLanguageState };

  return (
    <BrowserRouter>
      <div className='App'>
        <LanguageContext.Provider value={initialLanguageContext}>
          <Header />
          <main>
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/resume' element={<ResumePage />} />
              <Route path='/projects' element={<ProjectsPage />} />
              <Route path='/projects/good-bad-chad' element={<GoodBadChad />} />
              <Route path='/contact' element={<ContactPage />} />
            </Routes>
          </main>
        </LanguageContext.Provider>
      </div>
    </BrowserRouter>
  );
};

export default App;
export { LanguageContext };
