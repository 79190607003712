import { Link } from 'react-router-dom';
import '../../css/projectspage.css';
import gbcIcon from '../../img/icons/project/goodbadchad.png';
import uwIcon from '../../img/icons/project/uwapp.png';
const ProjectsPage = () => {
  return (
    <div className='ProjectsPage'>
      <h1>Projects</h1>
      <ProjectPanel
        name='The Good, the Bad, and Chad!'
        dateRange='January - March 2024'
        about={[
          `The Good, the Bad, and Chad! is a two-dimensional platformer game
          developed in vanilla JavaScript by a team of four. The game includes
          100% custom pixel art and music.`,
          `The player is Chad - a hunky young man from a peaceful (if not boring)
          little village. Chad is forced into a grand adventure when his mother
          is kidnapped by an evil wizard! He sets out into an adventure through
          rough terrains, fighting monsters armed with his sword and trusty
          slingshot.`
        ]}
        liveLink='https://goodbadchad.bigdevdog.com'
        learnMoreLink='/projects/good-bad-chad'
        icon={gbcIcon}
      />
      <ProjectPanel
        name='UW León Center Website'
        dateRange='June-August 2023'
        about={[
          `This project was the primary goal of my summer internship in León,
          Spain. A team of seven other interns and I were tasked with 
          redesigning the website for the University of Washington's center in
          León. The old website was in dire need of a cosmetic makeover, and we
          added functionalities such as testimonials from students who have
          already pursued study abroad opportunities at the center.`,
          `This project was made with HCI principles such as safety,
          accessibility, and efficiency at the forefront of our minds. The goal
          was to make information more readily available, and encourage other
          students to broaden their horizons and join a program there.`
        ]}
        liveLink='https://depts.washington.edu/leonctr/'
        learnMoreLink=''
        icon={uwIcon}
      />
    </div>
  );
};

const ProjectPanel = ({
  name,
  dateRange,
  about,
  liveLink,
  learnMoreLink,
  icon
}) => {
  return (
    <div className='box ProjectPanel'>
      <div className='top'>
        <div className='headline'>
          <img src={icon} className='project-icon' />
          <h3>{name}</h3>
          <h5>
            <i>{dateRange}</i>
          </h5>
        </div>
        <a href={liveLink} target='_blank'>
          <button>Live link</button>
        </a>
      </div>
      {about.map((para) => {
        return <p key={para}>{para}</p>;
      })}
      {learnMoreLink ? (
        <Link to={learnMoreLink}>
          <button>Learn more</button>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProjectsPage;
