import strings from '../../text/homepage.json';
import { useContext, useEffect } from 'react';
import { LanguageContext } from '../app';
import mern from '../../img/icons/mern.png';
import aws from '../../img/icons/aws.png';
import SlideShow from '../reusable/slideshow';

const HomePage = () => {
  const { languageState, setLanguageState } = useContext(LanguageContext);

  // useEffect(() => {
  //   setLanguageState('es');
  // });

  return (
    <div className='HomePage'>
      <h1>{strings.greeting[languageState]}</h1>
      <p>{strings.intro[languageState]}</p>
      <br />
      <br />
      <h2>What I do</h2>
      <SlideShow
        pics={[mern, aws]}
        text={[
          `I am a big fan of JavaScript, and for that reason I like to use the
          MERN stack for my web development projects - that is, MongoDB,
          Express, React, and Node.`,
          `I use AWS for cloud computing. I use Lightsail for hosting the
          virtual private servers which run the backends of my applications,
          including that for this very site!`
        ]}
        small
      />
    </div>
  );
};

export default HomePage;
