import { Link } from 'react-router-dom';

import '../css/header.css';

import strings from '../text/header.json';

import bddLogo from '../img/logos/logo_trans_celadon.png';
import ghLogo from '../img/icons/github-celadon.png';
import liLogo from '../img/icons/linkedin-celadon.png';
import { useContext } from 'react';
import { LanguageContext } from './app';

/**
 * The Header <header /> element. Contains logo (link to home), github and
 * linkedin links. Also contains <nav />.
 *
 * nav contains links to the pages home, resume, projects, and contact form.
 *
 * @returns The Header component.
 */
const Header = () => {
  return (
    <header>
      <div className='header-top'>
        <Link to='/'>
          <img src={bddLogo} />
        </Link>
        <div className='links'>
          <a href='https://www.github.com/b1gd3vd0g' target='_blank'>
            <img src={ghLogo} id='gh' />
          </a>
          <a href='https://www.linkedin.com/in/devinpeevy' target='_blank'>
            <img src={liLogo} id='li' />
          </a>
        </div>
      </div>
      <Nav />
    </header>
  );
};

const Nav = () => {
  const { languageState } = useContext(LanguageContext);
  return (
    <nav>
      <Link to='/'>
        <div className='nav-link'>
          <h4>{strings.home[languageState]}</h4>
        </div>
      </Link>
      <Link to='/projects'>
        <div className='nav-link'>
          <h4>{strings.projects[languageState]}</h4>
        </div>
      </Link>
      <Link to='/contact'>
        <div className='nav-link'>
          <h4>{strings.contact[languageState]}</h4>
        </div>
      </Link>
    </nav>
  );
};

export default Header;
