const requestSendContactFormEmail = async (
  name,
  email,
  phone,
  header,
  message
) => {
  const response = await fetch('https://www.api.bigdevdog.com:4041/contact', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: name,
      email: email,
      phone: phone,
      header: header,
      message: message
    })
  });
  const obj = await response.json();
  return obj;
};

module.exports = { requestSendContactFormEmail };
