import { useEffect, useState } from 'react';

const SlideShow = ({ pics, text = [], small = false }) => {
  const [currPic, setCurrPic] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrPic(currPic + 1 >= pics.length ? 0 : currPic + 1);
    }, 3_000);
    return () => clearInterval(interval);
  }, [currPic]);

  return (
    <div className='SlideShow'>
      <img src={pics[currPic]} className={small ? 'small' : ''} />
      {text[currPic] ? <p>{text[currPic]}</p> : <></>}
      <div className='dots'>
        {(() => {
          const dots = [];
          for (let i = 0; i < pics.length; i++) {
            const classes = `dot ${currPic === i ? 'currdot' : ''}`;
            dots.push(<div className={classes} key={`ssdot${i}`}></div>);
          }
          return dots;
        })()}
      </div>
    </div>
  );
};

export default SlideShow;
