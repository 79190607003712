import { Link } from 'react-router-dom';
// screenshots:
import ss1 from '../../../img/screenshots/gbc/chad.jpeg';
import ss2 from '../../../img/screenshots/gbc/chadcave.jpeg';
import ss3 from '../../../img/screenshots/gbc/chadend.jpeg';
import ss4 from '../../../img/screenshots/gbc/chadforest.jpeg';
import ss5 from '../../../img/screenshots/gbc/chadriver.jpeg';
import ss6 from '../../../img/screenshots/gbc/chadsnow.jpeg';
// slideshow component
import SlideShow from '../../reusable/slideshow';

const GoodBadChad = () => {
  return (
    <div className='GoodBadChad'>
      <Link to='/projects'>
        <button style={{ margin: '2vmin 0' }}>Back to projects</button>
      </Link>
      <h1>The Good, the Bad, and Chad!</h1>
      <h3>
        Developers: Caleb Krauter, Trae Claar, Nathan Hinthorne, Devin Peevy
      </h3>
      <br />
      <div className='linksbar'>
        <a
          href='https://github.com/GoodBadChad/good-bad-chad-br/tree/merge'
          target='_blank'>
          <button>Check out the code!</button>
        </a>
        <a href='https://www.goodbadchad.bigdevdog.com' target='_blank'>
          <button>Play now!</button>
        </a>
      </div>
      <SlideShow pics={[ss1, ss2, ss3, ss4, ss5, ss6]} />
      <div className='tools'>
        <h3>Tools used:</h3>
        <br />
        <ul>
          <li>JavaScript</li>
          <li>Git</li>
          <li>GitHub Projects</li>
          <li>Agile development</li>
          <li>Code review</li>
          <li>Piskel (for creating spritesheets)</li>
        </ul>
      </div>
      <br />
      <div className='my-cont'>
        <h3>What I did:</h3>
        <p>
          This project occupied a crazy amount of my time over the ten weeks of
          development, and realistically, everybody did a little bit of
          everything. But the following is a list of things that I personally
          accomplished and am most proud of:
        </p>
        <br />
        <ul>
          <li>
            Created the systems related to loading assets upon every zone change
          </li>
          <li>
            Developed a simple way to keep track of story progress, and how to
            use it to determine entity actions and loading scripts.
          </li>
          <li>
            Created a simple and very versatile dialog system, which can cause
            side effects (such as story progression and merchant system)
          </li>
          <li>
            Designed various spritesheets (some highlights by me: Mama and Papa
            Chad, Evil Wizard, Bunnies, and the Sun)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GoodBadChad;
